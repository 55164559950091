import gql from 'graphql-tag.macro'

export const templateField = gql`
  fragment templateField on template_fields {
    id
    isRequired
  }
`

export const template = gql`
  fragment template on templates {
    id
    name
    instructions
    icon
    template_fields {
      ...templateField
    }
  }
  ${templateField}
`
