import { React, Redirect } from 'library'

import useQuery from 'api/auth/useQuery'

const IsAuth = ({ login, protect }) => {
  const { loading, isAuth } = useQuery()
  if (login && isAuth) return <Redirect to={process.env.REACT_APP_APP_URL} />
  // TODO: this needs testing
  if (protect && !isAuth && !loading) return <Redirect to="/login" />
  return null
}

export default IsAuth
