import gql from 'graphql-tag.macro'
// import { gql } from '@apollo/client'

export const person = gql`
  fragment person on persons {
    email
    isVerified
    timestamp
    code
  }
`
