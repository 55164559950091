import React from 'react'
import PropTypes from 'prop-types'

import Logo from 'components/logos/Logo'
import cx from 'classnames'
import s from './styles.module.scss'

const LogoHeader = ({ className }) => {
  return (
    <nav className={cx(s.header, className)}>
      <Logo />
    </nav>
  )
}

LogoHeader.propTypes = {
  className: PropTypes.string,
}

export default LogoHeader
