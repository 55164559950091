import gql from 'graphql-tag.macro'

export const profile = gql`
  fragment profile on profiles {
    id
    name
    email
  }
`

// export const privateProfile = gql`
//   fragment privateProfile on profiles {

//   }
// `
