import gql from 'graphql-tag.macro'
import { profile } from 'api/fragments'

export const channel_member = gql`
  fragment channel_member on channel_members {
    id
    timestamp
    isManager
    profile {
      ...profile
    }
  }
  ${profile}
`
