import { useQuery } from '@apollo/client'
import gql from 'graphql-tag.macro'
import { person } from 'api/fragments'

// Is logged in?
// Server permissions only allow current logged in user to access (this way personId is never in UI)
const query = gql`
  query Auth {
    persons(limit: 1) {
      ...person
    }
  }
  ${person}
`

export default () => {
  const { data, loading } = useQuery(query, {
    onError: () => null,
    fetchPolicy: 'network-only',
    skip:
      process.env.NODE_ENV === 'production' &&
      navigator.userAgent === 'ReactSnap',
  })
  return {
    loading,
    isAuth: data ? data.persons[0]?.isVerified : null,
  }
}
