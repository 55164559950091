import cx from 'classnames'
import { Link, React, PropTypes } from 'library'

import s from './styles.module.scss'
// const Img = LoadImg(() => import('../img/logo-brown.svg'))
import img from '../img/logo-brown.svg'
const Logo = ({ className, to }) => {
  return (
    <Link to={to || '/'} className={cx(s.logo, className)}>
      <img src={img} alt="Fire Tower" title="Fire Tower" />
    </Link>
  )
}

Logo.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
}

export default Logo
